import React from "react";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import HouseIcon from "@mui/icons-material/House";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PersonIcon from "@mui/icons-material/Person";
import { Screenshot } from "@mui/icons-material";
import CodeIcon from "@mui/icons-material/Code";
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";
import DoneIcon from "@mui/icons-material/Done";
import MovieIcon from "@mui/icons-material/Movie";
import FolderIcon from "@mui/icons-material/Folder";
import BusinessIcon from "@mui/icons-material/Business";
import WebIcon from "@mui/icons-material/Web";
import StorageIcon from "@mui/icons-material/Storage";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import IsoIcon from "@mui/icons-material/Iso";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import GavelIcon from "@mui/icons-material/Gavel";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import BlockIcon from "@mui/icons-material/Block";
import RefreshIcon from "@mui/icons-material/Refresh";
import AttributionIcon from "@mui/icons-material/Attribution";
import LockIcon from "@mui/icons-material/Lock";

import Trailers from "./components/Tools/Trailers/Trailers";
import UsersList from "./components/Users/UsersList";

const MODULES = [
    {
        id: "screenshots",
        icon: <Screenshot />,
        right: "access_screenshots",
        children: [
            {
                id: "validation",
                icon: <DoneIcon />,
                path: "/screenshots/validation",
                right: "access_screenshots",
                sidebar: true,
            },
            {
                id: "advertiser_catalog",
                icon: <FolderIcon />,
                path: "/screenshots/catalog",
                right: "access_screenshots_catalog",
                sidebar: true,
            },
            {
                id: "companies_groups",
                icon: <BusinessIcon />,
                path: "/screenshots/companiesgroups",
                right: "access_screenshots_admin",
                sidebar: true,
            },
            {
                id: "screenshot_sites_pages",
                icon: <WebIcon />,
                path: "/screenshots/sites",
                right: "access_screenshots_admin",
                sidebar: true,
            },
            {
                id: "screenshot_position",
                icon: <AdUnitsIcon />,
                path: "/screenshots/positions",
                right: "access_screenshots_admin",
                sidebar: true,
            },
            {
                id: "screenshot_summary",
                icon: <StorageIcon />,
                path: "/screenshots/summary",
                right: "access_screenshots",
                sidebar: true,
            },
        ],
    },
    {
        id: "wrapper",
        icon: <CodeIcon />,
        right: "access_wrapper_adsconfig",
        children: [
            {
                id: "wrapper_adsconfig",
                icon: <AdUnitsIcon />,
                path: "/wrapper/adsconfig",
                right: "access_wrapper_adsconfig",
                sidebar: true,
            },
            {
                id: "wrapper_networkconfig",
                icon: <HouseIcon />,
                path: "/wrapper/networkconfig",
                right: "access_wrapper_networkconfig",
                sidebar: true,
            },
            {
                id: "wrapper_configurationsite",
                icon: <StorefrontIcon />,
                path: "/wrapper/configurationsite",
                right: "access_wrapper_configurationsite",
                sidebar: true,
            },
            {
                id: "dashboard_config",
                path: "/wrapper/dashboard",
                icon: <SpaceDashboardIcon />,
                right: "sites",
                sidebar: true,
            },
            {
                id: "wrapper_abtest",
                path: "/wrapper/abtest",
                icon: <IsoIcon />,
                right: "access_wrapper_abtest",
                sidebar: true,
            },
            {
                id: "wrapper_iab",
                path: "/wrapper/iab",
                icon: <SyncAltIcon />,
                right: "access_wrapper_iab",
                sidebar: true,
            },
            {
                id: "wrapper_renderingsite",
                path: "/wrapper/renderingsite",
                icon: <FormatPaintIcon />,
                right: "access_wrapper_renderingsite",
                sidebar: true,
            },
            {
                id: "wrapper_renderingnative",
                path: "/wrapper/renderingsite",
                icon: <FormatPaintIcon />,
                right: "access_wrapper_renderingnative",
                sidebar: true,
            },
            {
                id: "wrapper_prebid",
                path: "/wrapper/prebid",
                icon: <GavelIcon />,
                right: "access_wrapper_prebid_config",
                sidebar: true,
            },
            {
                id: "wrapper_adstxt",
                path: "/wrapper/adstxt",
                icon: <FactCheckIcon />,
                right: "access_wrapper_adstxt",
                sidebar: true,
            },
            {
                id: "wrapper_forbiddenwords",
                path: "/wrapper/forbiddenwords",
                icon: <BlockIcon />,
                right: "access_wrapper_forbiddenwords",
                sidebar: true,
            },
            {
                id: "wrapper_prebid_config",
                path: "/wrapper/prebid-config",
                icon: <MovieIcon />,
                right: "access_wrapper_prebid_config",
                sidebar: true,
            },
            {
                id: "wrapper_refresh",
                path: "/wrapper/refreshnew",
                icon: <RefreshIcon />,
                right: "access_wrapper_refresh",
                sidebar: true,
            },
            {
                id: "wrapper_dmp",
                path: "/wrapper/renderingsite",
                icon: <AttributionIcon />,
                right: "access_wrapper_dmp",
                sidebar: true,
            },
            {
                id: "wrapper_policy",
                path: "/wrapper/policy",
                icon: <LockIcon />,
                right: "access_wrapper_policy",
                sidebar: true,
            },
        ],
    },
    {
        id: "tools",
        icon: <BuildIcon />,
        right: "access_tools",
        children: [
            {
                id: "tools_trailers",
                path: "/tools/trailers",
                icon: <MovieIcon />,
                right: "access_tools_trailers",
                element: <Trailers />,
                sidebar: true,
            },
            {
                id: "tools_uploadcreas",
                path: "/tools/uploadcreas",
                icon: <DriveFolderUploadIcon />,
                right: "access_tools_uploadcreas",
                sidebar: true,
            },
        ],
    },
    {
        id: "administration",
        icon: <SettingsIcon />,
        right: "access_user",
        children: [
            {
                id: "users",
                path: "/administration/users",
                icon: <PersonIcon />,
                element: <UsersList />,
                right: "access_user",
                sidebar: true,
            },
        ],
    },
];

export default MODULES;
